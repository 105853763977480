import React, { useState, useRef, useEffect, useCallback, memo } from 'react';
import { Hidden, Grid, Typography, Tooltip } from '@material-ui/core';
import { Column, Table, AutoSizer, InfiniteLoader, defaultTableRowRenderer, List } from 'react-virtualized';
import { useDebounce } from 'use-debounce';
import { request, apis } from "../httpUtil";
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import actions from '../redux/actions';
import 'react-virtualized/styles.css';
import dayjs from 'dayjs';
import dateUtil from '../utils/date';
import DateRange from './DateRange';
import arrowDown from '../assets/images/victor/arrow-down.svg';
import arrowUp from '../assets/images/victor/arrow-up.svg';
import ExpandedRow from './DeliveriesList/ExpandedRow';
import ExportExcelFile from './ExportFile/ExportExcelFile';
import { STRINGS_CONST, SCHEDULED_ORDER_EMAIL_LOG_COLUMNS, ORDER_STATUS } from '../constants';
import ButtonWithIcon from './SubHeaderMenu/menuComponent';
import utils from '../utils';
import { withTranslation } from 'react-i18next';
import MobileBox from './MobileList/mobileBox';
import MobileList from './MobileList';
import useMobile from '../utils/useMobile';
const utc = require('dayjs/plugin/utc')
dayjs.extend(utc);
let history, dispatch, loading = false, initialLimit = { startIndex: 0, stopIndex: 10 };
const { EXCEL_FORMAT, SCHEDULED_ORDER_EMAIL_EXPORT_FILE_NAME, SCHEDULED_ORDER_EMAIL_TITLE } = STRINGS_CONST;
const SCHEDULED = "Scheduled", INTRANSIT = "In-Transit", IGNORED = "Ignored";

const Logs = ({ t: T, i18n }) => {
    dispatch = useDispatch();
    history = useHistory();
    const isMobile = useMobile();
    const tOpts = { t: T, i18n };
    const t = utils.t;
    const tableRef = useRef();
    const customExportRef = useRef();
    const dateFilterValues = useRef({ fromDate: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 7), toDate: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()) });
    const [fetchIndex, setFetchIndex] = useState(0);
    const userData = useSelector(state => state.appReducer.userData);
    const locationSearch = useSelector(state => state.appReducer.locationSearch);
    const scheduledLogsData = useSelector(state => state.appReducer.scheduledLogsData);
    // date time filters
    const filterValues = useSelector(state => state.appReducer.filterValues);
    let fromDate = useSelector(state => state.appReducer.fromDateFilterValue);
    let toDate = useSelector(state => state.appReducer.toDateFilterValue);


    if (filterValues && filterValues.fromDateFilterValue && filterValues.toDateFilterValue) {
        fromDate = filterValues.fromDateFilterValue;
        toDate = filterValues.toDateFilterValue;
    }
    if (!fromDate) {
        fromDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 7)
    }
    if (!toDate) {
        toDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())
    }
    // date filter end here

    const [searchDebounce] = useDebounce(locationSearch, 800)
    const { ClientId, DateFormat, CurrencySymbol, DateTimeFormat = '' } = userData && userData.tags ? userData.tags : 0;
    const dateFormat = (DateFormat && DateFormat.toUpperCase()) || dateUtil.formats.date;
    const [selectedIndex, setSelectedIndex] = useState(-1);

    // filter by date range
    const onGoClick = () => {
        dateFilterValues.current.fromDate = fromDate;
        dateFilterValues.current.toDate = toDate;
        getData(initialLimit)
    }
    // get filters location and date range here
    const getFilter = useCallback((params) => {
        if (locationSearch) {
            params = { ...params, ...{ locationName: locationSearch, isForRank: true } };
        }

        if (dateFilterValues.current.fromDate && dateFilterValues.current.toDate) {
            params = { ...params, ...{ startDate: dayjs.utc(dateFilterValues.current.fromDate).format(), endDate: dayjs.utc(dateFilterValues.current.toDate).format() } }
        }

        return params;
    }, [locationSearch, fromDate, toDate, t, tOpts.t]);

    const isRowLoaded = ({ index }) => !!scheduledLogsData.records[index];
    //fetch data
    const getData = useCallback(
        async ({ startIndex = 0, stopIndex }) => {
            if (!loading) {
                loading = true;
                let filter = [];
                if (startIndex !== 0 && fetchIndex === startIndex) return;
                setFetchIndex(startIndex)
                let params = {
                    action: "list", asArray: 0, limit: 10, start: startIndex, sort: 'SentOnUTC', dir: 'DESC'
                };
                params = getFilter(params, filter);
                if (Number(ClientId) > 0) {
                    params = { ...params, ...{ clientId: Number(ClientId) } }
                }
                const response = await request({ url: apis.ScheduledOrderMailHistory, params, history, dispatch });
                if (response && response.records) {
                    response.records.forEach(record => {
                        record.ScheduledOrderItem.forEach(item => item.UnitsNeeded = item.Quantity);
                    })
                    let firstFilter = false;
                    if ((locationSearch || fromDate || toDate) && !startIndex) {
                        firstFilter = true;
                        dispatch({ type: actions.SET_SCHEDULED_LOG, scheduledLogsData: { records: [], recordCount: 0, TotalCount: 0 } })
                    }
                    dispatch({ type: actions.SET_SCHEDULED_LOG, scheduledLogsData: { records: response.records, recordCount: response.recordCount, TotalCount: response.TotalCount, startIndex, firstFilter } })
                }
                loading = false;
            }
        },
        [locationSearch, ClientId, getFilter, fetchIndex, fromDate, toDate, onGoClick]
    )

    //Initial fetching data
    useEffect(() => {
        getData(initialLimit);
    }, []);

    useEffect(() => {
        return () => {
            dispatch({ type: actions.SET_SCHEDULED_LOG, scheduledLogsData: { records: [], recordCount: 0, TotalCount: 0, NeedAttentionCount: 0, firstFilter: true } });
            dispatch({ type: 'SET_LOCATION_SEARCH', locationSearch: "" });
        };
    }, [])
    useEffect(() => {
        if (searchDebounce || searchDebounce === '') {
            getData(initialLimit);
        }
    }, [searchDebounce]);

    let recordCount = scheduledLogsData && scheduledLogsData.recordCount ? scheduledLogsData.recordCount : 0
    let recordCountLst = scheduledLogsData && scheduledLogsData.records.length ? scheduledLogsData.records.length : 0

    const rowGetter = ({ index }) => {
        const data = (scheduledLogsData.records && scheduledLogsData.records[index]) || {}
        if (Object.keys(data).length <= 0) return false;
        return data
    };

    let memorizedHidden = React.useMemo(() => {
        return <Hidden smDown={false}>
            <div className="normal-filter mb-4  p-3 d-flex justify-content-center ">
                <Grid container spacing={1}>
                    <Grid item sm={12} md={12} lg={10} className="p-1">
                        <Grid container>
                            <Grid item md={12} sm={12}>
                                <DateRange isFromDate={true} selectedDate={fromDate} />
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item md={12} sm={12} >
                                <Typography className="p-3 text-center" variant="h5" component="h2" color="textSecondary">
                                    {t("To",tOpts)}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item md={12} sm={12} >
                                <DateRange isToDate={true} selectedDate={toDate} />
                            </Grid>
                        </Grid>
                        {/* <Grid item md={1} sm={1} className="pl-2">
                                <div className="pt-1"><Button variant="contained" color="primary" onClick={onGoClick}>Go</Button></div>
                            </Grid> */}
                    </Grid>
                </Grid>
            </div>
        </Hidden>
    }, [recordCountLst, fromDate, toDate, tOpts.t]);

    function clear(e) {
        dispatch({ type: actions.SET_FROM_DATE_FILTER, fromDateFilterValue: null });
        dispatch({ type: actions.SET_TO_DATE_FILTER, toDateFilterValue: null });
    }


    useEffect(() => {
        dispatch({ type: actions.SET_OPERATIONS_INFORMATION, operationsCheckedData: { onExportClick: onExportClick } });
        return () => {
            dispatch({ type: actions.SET_OPERATIONS_INFORMATION, operationsCheckedData: { onExportClick: null } });
        }
    }, [])

    useEffect(() => {
        dispatch({ type: actions.PASS_FILTERS_TOHEADER, filtersInHeader: { filterButton: memorizedHidden, clear, apply: onGoClick } });
        return () => {
            dispatch({ type: actions.PASS_FILTERS_TOHEADER, filtersInHeader: { filterButton: null, clear: null, apply: null, hidden: { search: true, operation: true, export: true, print: true, filter: true } } });
        }
    }, [fromDate, toDate])


    const Details = ({ children, index, clientId }) => (
        <div className="hover-cursor-pointer" onClick={() => {
            setSelectedIndex(index);
            tableRef.current.recomputeRowHeights();
        }}>
            {children}
        </div>
    );

    const expander = ({ rowIndex, rowData }) => {
        if (rowIndex !== selectedIndex) {
            return <Details index={rowIndex} clientId={rowData.ClientId}><img height={12} src={arrowDown} alt={`arrow`} /></Details>
        } else {
            return <Details index={-1}><img height={12} src={arrowUp} alt={`arrow`} /></Details>
        }
    }

    const rowRenderer = props => {
        let { index, style, key, rowData } = props;
        if (!rowData) {
            rowData = (scheduledLogsData.records && scheduledLogsData.records[index]) || {}
        }
        const productData = rowData.ScheduledOrderItem ? rowData.ScheduledOrderItem : [];
        const currency = rowData.Currency
        if (index === selectedIndex) {
            return (
                <div style={{ ...style, display: "flex", flexDirection: "column" }} key={key} >
                    {isMobile.mobile ? listItems({ rowData, index, style: { position: "relative" } }) :
                        defaultTableRowRenderer({ ...props, style: { width: style.width, height: 76 } })}
                    <div className='p-2 scroll-bar'>
                        <ExpandedRow data={productData} locationId={rowData.LocationId} currency={currency}/>
                    </div>
                </div>
            );
        }
        return isMobile.mobile ? listItems({ ...props, rowData }) : defaultTableRowRenderer(props);
    };
    const order_renderer = ({ cellData, rowData }) => {
        if (!cellData && !rowData) {
            return;
        }
        cellData = (location_has_latest_asset_data(rowData) && rowData.LatestAssetImageTotalOrder != undefined) ? rowData.LatestAssetImageTotalOrder : cellData;
        return <div style={{ color: '#11ad34' }}> {cellData && (rowData.Currency && rowData.Currency.replace("{0}", Math.round(cellData)))}</div>
    }
    function location_has_latest_asset_data(rowData) {
        return ((rowData.TotalAssetCount - rowData.AssetCountWithOldImage) > 0);
    }
    const total_case_renderer = ({ cellData, rowData }) => {
        if (!cellData && !rowData) {
            return;
        }
        cellData = (location_has_latest_asset_data(rowData) && rowData.LatestAssetImageTotalCase != undefined) ? rowData.LatestAssetImageTotalCase : cellData;
        return <div> {cellData}</div>
    }
    const out_of_stock_renderer = ({ cellData, rowData }) => {
        if (!cellData && !rowData) {
            return;
        }
        cellData = (location_has_latest_asset_data(rowData) && rowData.LatestAssetImageOutOfStock != undefined) ? rowData.LatestAssetImageOutOfStock : cellData;
        return <div>{cellData && (Math.round(cellData) + '%')}</div>
    }

    const _getRowHeight = ({ index }) => (index === selectedIndex ? 350 : 76);
    const _getRowHeightMobile = ({ index }) => (index === selectedIndex ? 450 : 350);

    const onExportClick = () => {
        let params = {};
        let additionalParams = "forExport=true";
        params = getFilter(params);
        if (Number(ClientId) > 0) {
            params = { ...params, ...{ clientId: Number(ClientId) } }
        }
        customExportRef.current.setExportParams({ ExportCols: utils.getExportColumn(CurrencySymbol, 'Amount', JSON.parse(JSON.stringify(SCHEDULED_ORDER_EMAIL_LOG_COLUMNS))), filters: params, title: SCHEDULED_ORDER_EMAIL_TITLE, fileName: SCHEDULED_ORDER_EMAIL_EXPORT_FILE_NAME, format: EXCEL_FORMAT, additionalParams: additionalParams });
    }

    const listItems = ({ style, index, key, rowData }) => {
        const OutOfStock = (location_has_latest_asset_data(rowData) && rowData.LatestAssetImageOutOfStock != undefined) ? rowData.LatestAssetImageOutOfStock : rowData["OutOfStock"];
        const restockInData = rowData["RestockIn"] != null && rowData["RestockIn"] >= 0 ? (rowData["RestockIn"] + ' days') : 'N/A';
        const totalCase = (location_has_latest_asset_data(rowData) && rowData.LatestAssetImageTotalCase != undefined) ? rowData.LatestAssetImageTotalCase : rowData["TotalCase"];
        let totalOrder = (location_has_latest_asset_data(rowData) && rowData.LatestAssetImageTotalOrder != undefined) ? rowData.LatestAssetImageTotalOrder : rowData["TotalOrder"];
        const OrderStatusId = rowData["OrderStatusId"];
        let orderStatus = OrderStatusId == ORDER_STATUS.IGNORED ? IGNORED :
            OrderStatusId == ORDER_STATUS.SCHEDULED ? SCHEDULED :
                OrderStatusId == ORDER_STATUS.SCHEDULED ? INTRANSIT :
                    "N/A";

        const menuItems = [{
            label: index !== selectedIndex ? 'EDIT' : "CLOSE",
            onClick: (e) => {
                let setIndex = -1
                if (index !== selectedIndex) {
                    setIndex = index
                }
                setSelectedIndex(setIndex);
                tableRef.current.recomputeRowHeights();
                if (index > -1) {
                    // setComboValues(rowData.clientId);
                }
            },
            disabled: false,
            color: 'background-theme-blue text-white',
            show: true
        },

        ]
        const elements = [{
            className: "mb-1",
            Components: () => {
                return <>

                    <p className="mb-0 ellipse w-100 p-2 text-center"><b>{rowData["LocationName"]}</b></p>
                    <ButtonWithIcon useHeader={false} showLabelOnMobile={true} label={"Action"} btnClass={"background-theme-blue"} menuItems={menuItems} />
                </>
            }
        },

        {
            className: "justify-content-between",
            Components: () => {
                return <>
                    <MobileBox header={t("Out of Stock", tOpts)} value={OutOfStock && (rowData.IsActive || rowData.ScheduledOrderId) ? (Math.round(OutOfStock) + '%') : '0%'} />
                    <MobileBox header={t('Restock In', tOpts)} value={(restockInData)} />
                    <MobileBox header={t("Amount", tOpts)} value={(<><span>{(rowData.IsActive || rowData.ScheduledOrderId) ? totalCase : 0} cases </span>   <span className='text-success pl-1' >{(rowData.Currency != null ? rowData.Currency.replace("{0}", Math.round(totalOrder)) : totalOrder)}</span> </>)} />
                </>
            }
        },
        {
            className: "justify-content-between",
            Components: () => {
                return <>
                    <MobileBox header={t("Report Type", tOpts)} value={<div>{rowData["ReportType"] || "N/A"}</div>} />
                    <MobileBox header={t("Order Status", tOpts)} value={<div>{orderStatus}</div>} />
                </>
            }
        },

        {
            className: "justify-content-between",
            Components: () => {
                return <>
                    <MobileBox header={t("Order Date", tOpts)} value={<div>{dayjs(rowData["OrderDate"]).format(dateFormat)}</div>} />
                    <MobileBox header={t("Delivery Date", tOpts)} value={<div>{dayjs(rowData["DeliveryDate"]).format(dateFormat)}</div>} />
                    <MobileBox header={t("Sent On", tOpts)} value={(<div className='d-flex flex-column'><div>{dayjs(rowData["SentOnUTC"]).format(DateTimeFormat?.toUpperCase() || dateUtil.formats.dateTime)}</div></div>)} />
                </>
            }
        },
        {
            className: "justify-content-center",
            Components: () => (<div className=' pl-1' >{rowData["ClientName"]}</div>)
        }
        ]
        return (<div key={key} style={{ ...style, width: "100%", display: "flex", height: "auto", padding: "5px", background: "white", borderRadius: "2%" }}>
            <MobileList elements={elements} />
        </div>)
    }

    return (<div className={` virtual-list-wrapper log-screen  mb-1 p-0  ${isMobile.portrait ? "mt-4" : "mt-2"}`}>
        <div className="table-container" style={{ height: 580 }}>
            <Grid container spacing={1} className="w-100 justify-content-between">
                <Grid item sm={6} md={6} lg={3}>
                    <span className="virtualized-table-title">{t("Logs", tOpts)}</span>
                </Grid>
                <Grid item sm={6} md={6} lg={9}>
                    <Grid container justifyContent="flex-end" spacing={1}>
                        <Grid item > <span className="list-record-count display-5 text-black-50"> {t("Showing {{recordCount}} Records", tOpts, { recordCount })}</span></Grid>
                    </Grid>
                </Grid>
            </Grid>
            <InfiniteLoader
                loadMoreRows={getData}
                isRowLoaded={isRowLoaded}
                threshold={2}
                rowCount={recordCount}>
                {({ onRowsRendered, ref }) => (
                    <AutoSizer>
                        {({ height, width }) => (
                            isMobile.mobile ? <List
                                width={width}
                                height={height}
                                className="adjust"
                                ref={tableRef}
                                rowCount={recordCountLst}
                                rowHeight={_getRowHeightMobile}
                                rowRenderer={rowRenderer}
                                onRowsRendered={onRowsRendered} /> :
                                <Table
                                    width={width}
                                    height={height - 69}
                                    headerHeight={42}
                                    rowHeight={_getRowHeight}
                                    rowCount={recordCountLst}
                                    ref={tableRef}
                                    rowGetter={rowGetter}
                                    onRowsRendered={onRowsRendered}
                                    rowRenderer={rowRenderer} >
                                    <Column
                                        className="location-column-header"
                                        label={t('LOCATION', tOpts)}
                                        headerClassName="text-align-left pl-2"
                                        dataKey='LocationName'
                                        width={width * 0.30}
                                        cellRenderer={({ cellData, rowData }) => {
                                            if (!cellData && !rowData) {
                                                return;
                                            }
                                            return (<div className="d-flex align-items-center">
                                                <Tooltip title={rowData.LocationAddress + " " + cellData}>
                                                    <div className="pl-0 pl-sm-3">
                                                        <p className="mb-0">{cellData}</p>
                                                        <small className="text-black-50 pt-1 "> {rowData.LocationAddress}</small>
                                                    </div>
                                                </Tooltip>
                                            </div>)
                                        }}
                                    />
                                    <Column
                                        className="location-table-data-numeric"
                                        label={t('OUT OF STOCK', tOpts)}
                                        dataKey='OutOfStock'
                                        width={width * 0.15}
                                        headerStyle={{ textAlign: 'center' }}
                                        justifyContent={'center'}
                                        cellRenderer={out_of_stock_renderer} />
                                    <Column
                                        className="location-table-data-numeric"
                                        label={t('RESTOCK IN', tOpts)}
                                        dataKey='RestockIn'
                                        width={width * 0.12}
                                        cellRenderer={({ cellData, rowData }) => {
                                            if (!cellData && !rowData) {
                                                return;
                                            }
                                            return (<div>{cellData ? (Math.round(cellData) + ' days') : 'N/A'}</div>)
                                        }}
                                    />
                                    <Column
                                        className="location-table-data-numeric"
                                        label={t('CASES', tOpts)}
                                        dataKey='TotalCase'
                                        width={width * 0.12}
                                        cellRenderer={total_case_renderer} />
                                    <Column
                                        className="location-table-data-numeric"
                                        label={t('ORDER', tOpts)}
                                        dataKey='TotalOrder'
                                        width={width * 0.12}
                                        cellRenderer={order_renderer} />
                                    <Column
                                        className="presales-column-header"
                                        label={t('Order Date', tOpts)}
                                        dataKey='OrderDate'
                                        width={width * 0.15}
                                        cellRenderer={({ cellData, rowData }) => {
                                            if (!cellData && !rowData) {
                                                return;
                                            }
                                            return dayjs(cellData).format(dateFormat)
                                        }}
                                    />
                                    <Column
                                        className="presales-column-header"
                                        label={t('Delivery Date', tOpts)}
                                        dataKey='DeliveryDate'
                                        width={width * 0.15}
                                        cellRenderer={({ cellData, rowData }) => {
                                            if (!cellData && !rowData) {
                                                return;
                                            }
                                            return dayjs(cellData).format(dateFormat)
                                        }}
                                    />
                                    <Column
                                        className="presales-column-header"
                                        label={t('Order Number', tOpts)}
                                        dataKey='OrderNumber'
                                        width={width * 0.20}
                                    />
                                    <Column
                                        className="presales-column-header"
                                        label={t('Customer Name', tOpts)}
                                        dataKey='ClientName'
                                        width={width * 0.20}
                                    />
                                    <Column
                                        className="presales-column-header"
                                        label={t('Report Type', tOpts)}
                                        dataKey='ReportType'
                                        width={width * 0.20}
                                        cellRenderer={({ cellData, rowData }) => (cellData || "N/A")}
                                    />
                                    <Column
                                        className="presales-column-header"
                                        label={t('Order Status', tOpts)}
                                        dataKey='OrderStatusId'
                                        width={width * 0.20}
                                        cellRenderer={({ cellData }) => {
                                            if (cellData === ORDER_STATUS.IGNORED) {
                                                return IGNORED;
                                            } else if (cellData === ORDER_STATUS.SCHEDULED) {
                                                return SCHEDULED
                                            } else if (cellData === ORDER_STATUS.INTRANSIT) {
                                                return INTRANSIT
                                            }
                                            return "N/A"
                                        }}
                                    />
                                    <Column
                                        className="presales-column-header"
                                        label={t('Sent On', tOpts)}
                                        dataKey='SentOnUTC'
                                        width={width * 0.15}
                                        cellRenderer={({ cellData, rowData }) => {
                                            if (!cellData && !rowData) {
                                                return;
                                            }
                                            return dayjs(cellData).format(DateTimeFormat?.toUpperCase() || dateUtil.formats.dateTime)
                                        }}
                                    />
                                    <Column
                                        className="level-column-header"
                                        cellDataGetter={({ rowData }) => rowData.length}
                                        dataKey="index"
                                        width={width * 0.09}
                                        cellRenderer={expander} />
                                </Table>
                        )}
                    </AutoSizer>
                )}
            </InfiniteLoader>
            <ExportExcelFile ref={customExportRef} url={apis.ScheduledOrderMailHistory} />
        </div>
    </div>)
}

export default withTranslation()(memo(Logs));