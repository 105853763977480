import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import actions from '../../redux/actions';
import { useHistory } from 'react-router-dom'
import DataGridBase from '../DataGrid';
import models from '../../config/models';
import PageTitle from '../PageTitle';
import dbUtil from '../../pages/RenderSurveyAnswer/dbUtil';
import { apis } from '../../httpUtil';
import utils from "../../utils"
import { useTranslation } from 'react-i18next';
import useMobile from '../../utils/useMobile';
import { useLocation } from 'react-router';
import constants from '../../utils/constants';

export default function SurveyReport() {
    const t = utils.t
    const { t: translate, i18n } = useTranslation()
    const tOpts = { t: translate, i18n };
    const history = useHistory()
    const dispatch = useDispatch()
    const titleHeading = t("Survey Inbox Overview", tOpts)
    const titleDescription = t("Review submitted surveys individually", tOpts)
    const isMobile = useMobile()
    const location = useLocation();

    const onGridCellDoubleClick = async (RowNumber, data) => {
        const { SurveyTypeId, SurveyId, LocationId, SurveyType, OutletName, ModifiedByUser, EndDate, OutletCode } = data;
        if (!SurveyTypeId || !SurveyId || !LocationId) {
            return false;
        }
        dbUtil.set("surveyData", {
            SurveyId: SurveyId,
            SurveyTypeId: SurveyTypeId,
            SurveyType: SurveyType,
            OutletName: OutletName,
            ModifiedByUser: ModifiedByUser,
            SurveyDateTime: EndDate,
            OutletCode: OutletCode
        });

        history.push(`/Survey/SurveyReport/${LocationId}`);
    }
    useEffect(() => {
        dispatch({ type: actions.SET_PAGE_TITLE_DETAILS, pageTitleDetails: <PageTitle icon="" titleHeading={titleHeading} titleDescription={titleDescription} title={titleHeading} /> });
    }, [])
    useEffect(() => {
        let backRoute = location.pathname;
        backRoute = backRoute.split("/");
        backRoute.pop();
        backRoute = backRoute.join("/");
        dispatch({
            type: actions.SET_PAGE_BACK_BUTTON,
            pageBackButton: { status: true, backRoute: backRoute },
        });
        return () => {
            utils.removeBackButton(dispatch);
        }
    }, []);
    return (
        <div className={`mb-1 p-0  ${isMobile.portrait ? "mt-4" : "mt-2"}`} >
            <DataGridBase url={apis.Survey} gridConfig={models.surveyDataModel} pageTitle={titleHeading} onGridCellDoubleClick={onGridCellDoubleClick} sortingMode='server' pageType={constants.surveyInboxPageType} />
        </div>
    )
}