import * as React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import dayjs from 'dayjs';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { transport } from '../../httpUtil/httpRequest';
import { apis } from '../../httpUtil';
import HorizontalBarChartComponent from '../Chart/HorizontalBarChart';
import Paper from '@mui/material/Paper';
import MultiSelectDropdown from '../mutliSelectDropdown';
import actions from '../../redux/actions';
import PageTitle from '../PageTitle';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    ...theme.applyStyles('dark', {
        backgroundColor: '#1A2027',
    }),
}));

const config = {
    label: "Clients",
    titleMessage: 'Select & Unselect All',
    multiSelectLabel: 'Multiple Clients',
}

export default function HorizontalBars() {

    const [data, setData] = React.useState({ products: [], outlets: [], markets: [], distributors: [], totalCases: 0, CurrencySymbol: '$' });
    const [selectedClients, setSelectedClients] = React.useState([]);
    const [dateDiff, setDateDiff] = React.useState(30);
    const [dateValue, setDateValue] = React.useState([dayjs().subtract(30, 'day'), dayjs()]);
    const clientsList = useSelector(state => state.appReducer.clientsList) || {};
    const dispatch = useDispatch();
    const { t: translate } = useTranslation();
    const title = translate('Missed Opportunities');

    const getData = async ({ applyClientFilter = true }) => {
        dispatch({ type: 'UPDATE_LOADER_STATE', loaderOpen: true });
        const timeFilter = dateValue ? [{ value: dayjs(dateValue[0]).format('YYYY-MM-DD'), fieldName: "dbo.ConsumptionLog.PurityDateOnly", operator: ">" }, { value: dayjs(dateValue[1]).format('YYYY-MM-DD'), fieldName: "dbo.ConsumptionLog.PurityDateOnly", operator: "<" }] : null;
        const aggregates = {
            "Market": {
                "selectColumnName": "dbo.Market.MarketName",
                "groupByColumns": "subQuery.DistributorId, subQuery.MarketId",
                "innerQuerySelectColumns": "DistributorId, MarketId",
                "filter": "dbo.Market.MarketId > 0",
                "outerGroupByColumn": "MarketId",
                "limit": 5,
                "joinTableName": "dbo.Market",
                "joinColumnName": "dbo.Market.MarketId",
                "joinParentColumnName": "outerQuery.MarketId"
            },
            "Distributor": {
                "selectColumnName": "dbo.Distributor.DistributorName",
                "groupByColumns": "subQuery.DistributorId, subQuery.MarketId",
                "innerQuerySelectColumns": "DistributorId, MarketId",
                "filter": "dbo.Distributor.DistributorId > 0",
                "outerGroupByColumn": "DistributorId",
                "limit": 5,
                "joinTableName": "dbo.Distributor",
                "joinColumnName": "dbo.Distributor.DistributorId",
                "joinParentColumnName": "outerQuery.DistributorId"
            },
            "Product": {
                "selectColumnName": "dbo.Product.Product AS ProductName",
                "groupByColumns": "subQuery.LocationId, subQuery.ProductId",
                "innerQuerySelectColumns": "LocationId, ProductId",
                "filter": "dbo.ConsumptionLog.ProductId > 0",
                "outerGroupByColumn": "ProductId",
                "joinTableName": "dbo.Product",
                "joinColumnName": "dbo.Product.ProductId",
                "joinParentColumnName": "outerQuery.ProductId"
            },
            "Outlet": {
                "selectColumnName": "dbo.Location.Name AS LocationName",
                "groupByColumns": "subQuery.LocationId, subQuery.ProductId",
                "innerQuerySelectColumns": "LocationId, ProductId",
                "filter": "dbo.ConsumptionLog.LocationId > 0",
                "outerGroupByColumn": "LocationId",
                "joinTableName": "dbo.Location",
                "joinColumnName": "dbo.Location.LocationId",
                "joinParentColumnName": "outerQuery.LocationId"
            }
        };

        const params = { aggregates, timeFilter }
        if (applyClientFilter) {
            params.selectedClients = selectedClients;
        }

        try {
            const { data } = await transport({
                url: apis.missedOpportunityAPI,
                data: params,
                method: 'POST',
            });

            const { Product = [], Outlet = [], Market = [], Distributor = [], CurrencySymbol, totalCases = 0 } = data;
            const mapData = (arr, keyLabel) => arr.map(({ MissedOpportunityCases: value, [keyLabel]: label }) => ({ value, label }));
            const products = mapData(Product, "ProductName");
            const outlets = mapData(Outlet, "LocationName");
            const markets = mapData(Market, "MarketName");
            const distributors = mapData(Distributor, "DistributorName");
            setData({ products, outlets, markets, distributors, totalCases, CurrencySymbol });
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            dispatch({ type: 'UPDATE_LOADER_STATE', loaderOpen: false });
        }
    };

    React.useEffect(() => {
        getData({ applyClientFilter: true });
    }, []);


    React.useEffect(() => {
        dispatch({ type: actions.SET_PAGE_TITLE_DETAILS, pageTitleDetails: <PageTitle icon="" titleHeading='Missed Opportunity' titleDescription='Missed Opportunity is the value of products being unavailable in stores, achieve this with a calculation on the sales rate of each product on each store and the projection of sales based on time the product were unavailable.' title='Missed Opportunity' /> });
    }, [])

    const handleClientSelectChange = (event) => {
        const value = event.target.value;
        if (value[value.length - 1] === -1) {
            setSelectedClients(selectedClients.length === clientsList.length ? [] : clientsList.map(client => client.Key));
            return;
        }
        setSelectedClients(value);
    };

    function onResetClick() {
        setSelectedClients();
        setDateValue([dayjs().subtract(30, 'day'), dayjs()]);
        getData({ applyClientFilter: false });
    }

    function onApplyFilterClick() {
        const selectedDate = dayjs(dateValue[0]).format('YYYY-MM-DD');
        const currentDate = dayjs(dateValue[1]).format('YYYY-MM-DD');
        const differenceInDays = dayjs(currentDate).diff(dayjs(selectedDate), 'day');
        setDateDiff(differenceInDays);
        getData({ applyClientFilter: true });
    }

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Item>
                        <div>
                            <div style={{ fontSize: 'large', fontWeight: 500, color: 'black' }}>
                                Total Cases
                            </div>
                            <div style={{ fontSize: 'large', fontWeight: 500, color: 'black' }}>
                                {data.totalCases}
                            </div>
                        </div>
                    </Item>
                </Grid>
                <Grid item xs={6}>
                    <Item>
                        <div style={{ fontSize: 'large', fontWeight: 500, color: 'black', marginTop: 15, marginBottom: 10 }}>
                            Missed Opportunity {dateDiff ? `- Last ${dateDiff} Days` : null}
                        </div>
                    </Item>
                </Grid>

                <Grid item xs={2.5} style={{ marginTop: 10 }}>
                    <Item>
                        <div style={{ fontSize: 'large', fontWeight: 500, color: 'black', marginBottom: 10 }}>
                            Filters
                        </div>
                        <div>
                            <DateRangePicker value={dateValue} onChange={(newValue) => setDateValue(newValue)} />
                        </div>
                        <div style={{ marginTop: 15 }} className="missed-opportunity-client-select">
                            <MultiSelectDropdown config={config} options={clientsList} selectedOptions={selectedClients} handleChange={handleClientSelectChange} addMargin={false} width={220} />
                        </div>
                        <div style={{ marginTop: 10, display: 'flow-root' }}>
                            <Button variant="text" style={{ float: 'left' }} onClick={() => onResetClick()}>Reset</Button>
                            <Button variant="text" style={{ float: 'right' }} onClick={() => onApplyFilterClick()}>Apply Filter</Button>
                        </div>
                    </Item>
                </Grid>
                <Grid item xs={9.5} style={{ marginTop: 10 }}>
                    <Item style={{ background: 'transparent', boxShadow: 'none', padding: 0 }}>
                        <Grid container spacing={2} style={{ backgroundColor: '#efeff7' }}>
                            <Grid item xs={6} sm={6}>
                                <Item>
                                    <HorizontalBarChartComponent dataset={data.products} title='Products - Top 10' yaxisname="Value Per Product" color='#3B18AF' />
                                </Item>
                            </Grid>
                            <Grid item xs={6} sm={6}>
                                <Item >
                                    <HorizontalBarChartComponent dataset={data.outlets} title='Outlets - Top 10' yaxisname="Value Per Outlet" color='#8496AE' />
                                </Item>
                            </Grid>
                            <Grid item xs={6} sm={6}>
                                <Item>
                                    <HorizontalBarChartComponent dataset={data.markets} title='Market Total - Top 5' yaxisname="Value Per Market" color='#14659C' />
                                </Item>
                            </Grid>
                            <Grid item xs={6} sm={6}>
                                <Item>
                                    <HorizontalBarChartComponent dataset={data.distributors} title='Distributor Total - Top 5' yaxisname="Value Per Distributor" color='#2397E6' />
                                </Item>
                            </Grid>
                        </Grid>
                    </Item>
                </Grid>
            </Grid>
        </Box>
    );
}