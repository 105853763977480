import React from "react";
import FusionCharts from "fusioncharts";
import charts from "fusioncharts/fusioncharts.charts";
import ReactFusioncharts from "react-fusioncharts";

charts(FusionCharts);

function HorizontalBarChart({ dataset, title, width = 550, height = 500, yaxisname = 'value', theme = 'fusion', color = '#1aaf5d' }) {

    const dataSource = {
        chart: {
            caption: title,
            yaxisname: yaxisname,
            aligncaptionwithcanvas: "0",
            plottooltext: "<b>$dataValue</b>",
            theme: theme,
            bgColor: "#ffffff",
            bgAlpha: "50",
            paletteColors: color,
            canvasBgColor: "#ffffff",
            showAlternateVGridColor: "0",
            divLineColor: "#d3d3d3",
            divLineAlpha: "100",
            vDivLineColor: "#d3d3d3",
            vDivLineAlpha: "100",
            plotSpacePercent: "20",
            showYAxisLine: "0",
            showXAxisLine: "0",
            showBorder: "0",
            showPlotBorder: "0",
            usePlotGradientColor: 0
        },
        data: dataset
    };

    return (
        <div>
            <ReactFusioncharts
                type="bar2d"
                width={width}
                height={height}
                dataFormat="JSON"
                dataSource={dataSource}
            />
        </div>
    );
}

export default HorizontalBarChart;