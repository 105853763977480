import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import { useSelector, useDispatch } from 'react-redux';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import assetListModel from '../data-asset';
import dataPivotModel from '../data-pivot';
import PageLeftFilters from './PageLeftFilters';
import actions from '../../redux/actions';
import SplitButton from './SplitButtonComponent';
import constants from '../../utils/constants';
const { groupByMappings } = constants;

const isAnyOfFilters = ["MarketId", "LocationTypeId", "ClassificationId", "DistributorId", "SalesRepId", "RouteId", "PreSellerRouteId"];

const containsFilters = ["Location", "LocationCode"];

const equalsFilters = ["IsSmart"];

const groupByOptions = [{ key: 'MarketId', label: 'Market' }, { key: 'City', label: 'City', isStringField: true }, { key: 'PrimarySalesRep', label: 'Sales Rep', isStringField: true }, { key: 'State', label: 'State', isStringField: true }, { key: 'DistributorId', label: 'Distributor' }, { key: 'LocationTypeId', label: 'Channel' }, { key: 'PreSellerRouteId', label: 'Pre Seller Route' }]

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function GroupByComponent({ groupBy, groupByOptions }) {
    return <Paper sx={{ mt: 1, ml: 1, zIndex: 4 }}>
        <SplitButton options={groupByOptions} groupBy={groupBy} />
    </Paper>
}

export default function DataPage() {
    const dispatch = useDispatch();
    const [value, setValue] = useState(0);
    const [filtersUpdated, setFiltersUpdated] = useState();
    const [selectedClients, setSelectedClients] = useState([]);
    const pageLeftFilters = useSelector(state => state.appReducer.pageLeftFilters);
    const groupBy = useSelector(state => state.appReducer.dataGroupBy);
    const [activeLeftfilters, setActiveLeftFilters] = useState([]);
    const userData = useSelector(state => state.appReducer.userData) || {};
    const { ClientId } = userData?.tags || {};
    const renderField = groupByMappings[groupBy];


    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const submitFilters = ({ filters }) => {
        const finalFilters = [];
        Object.keys(filters).map(field => {
            const value = filters[field];
            if (value?.length && isAnyOfFilters.includes(field)) {
                finalFilters.push({ field, operator: "isAnyOf", value })
            }

            if (value?.length && containsFilters.includes(field)) {
                finalFilters.push({ field, operator: "contains", value })
            }

            if (value > -1 && equalsFilters.includes(field)) {
                finalFilters.push({ field, operator: "equals", value })
            }
        })
        setSelectedClients(filters.ClientId);
        setActiveLeftFilters(finalFilters);
    }

    const resetFilters = () => {
        setSelectedClients([]);
        dispatch({ type: actions.SET_PAGE_LEFT_FILTERS, filters: { ClientId: [Number(ClientId)], MarketId: [], LocationTypeId: [], ClassificationId: [], DistributorId: [], selectedSalesRep: [], selectedRoutes: [], PreSellerRouteId: [], LocationCode: '', Location: '', IsSmart: -1 } });
        setActiveLeftFilters();
    }

    const getDefaultFilters = () => {
        const selectedGroupBy = [groupBy];
        const defaultFilter = [];
        if (!selectedGroupBy.includes('MarketId')) {
            selectedGroupBy.push('MarketId')
        }
        for (const filter of selectedGroupBy) {
            const isStringField = groupByOptions.filter(ele => ele.key === filter)[0]?.isStringField || false;
            const operator = isStringField ? 'IS NOT NULL' : '>';
            const value = isStringField ? '' : '0';
            defaultFilter.push({ field: filter, operator, value })
        }
        return activeLeftfilters ? [...defaultFilter, ...activeLeftfilters] : defaultFilter;
    }

    const defaultFilters = getDefaultFilters();

    useEffect(() => {
        resetFilters();
    }, []);

    useEffect(() => {
        setFiltersUpdated(Math.random());
    }, [activeLeftfilters, groupBy]);

    return (
        <Box sx={{ width: '100%' }}>
            <Grid container spacing={2}>
                <Grid item xs={3} style={{ paddingTop: 0 }}>
                    <PageLeftFilters onSubmit={submitFilters} resetFilters={resetFilters} />
                </Grid>
                <Grid item xs={9} style={{ maxHeight: 750, overflowY: 'auto' }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 1, borderRadius: 1 }}>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                            <Tab label="Assets" {...a11yProps(0)} />
                        </Tabs>
                    </Box>

                    <Paper sx={{ mt: 1, mb: 3 }}>
                        <dataPivotModel.Grid renderField={renderField} additionalFilters={defaultFilters} reRenderKey={filtersUpdated} selectedClients={selectedClients} gridStyle={{ maxHeight: 400, overflowY: 'auto' }} customHeaderComponent={<GroupByComponent groupBy={groupBy} groupByOptions={groupByOptions} />} />
                    </Paper>

                    <CustomTabPanel value={value} index={0}>
                        <assetListModel.Grid additionalFilters={activeLeftfilters} reRenderKey={activeLeftfilters} selectedClients={selectedClients} />
                    </CustomTabPanel>
                </Grid>
            </Grid>
        </Box>
    );
}
