import UiModel from './ui-model';
import {  getGridStringOperators } from '@mui/x-data-grid-premium';

const stringFilterOperators = getGridStringOperators().filter((operator) => operator.value === 'contains');
const outletTabModel = new UiModel({
    title: "Master Data",
    defaultSort: 'Name DESC',
    titleDescription: "Check how your master data is set",
    idProperty: 'LocationId',
    readOnly: true,
    api: 'Location',
    addCreatedModifiedColumns: false,
    preferenceId: 'playbookMasterOutlet',
    columns: [
        { field: "Name", label: "Name", type: 'string', minWidth: 70 },
        { field: "Code", label: "Code", type: 'string', minWidth: 100 },
        { field: "Country", label: "Country", type: 'string', minWidth: 80 },
        { field: "State", label: "State", type: 'string', minWidth: 60 },
        { field: "City", label: "City", type: 'string', minWidth: 50, sqlType:'nvarChar' },
        { field: "Street", label: "Street", type: 'string', minWidth: 60, sqlType:'nvarChar' },
        { field: "PostalCode", label: "Postal Code", type: 'string', minWidth: 100 },
        { field: "MarketName", label: "Market", type: 'string', minWidth: 70 },
        { field: "LocationType", label: "Channel", type: 'string', minWidth: 80 },
        { field: "Classification", label: "Classification", type: 'string', minWidth: 100 },
        { field: "Latitude", label: "Latitude", type: "number", minWidth: 80, isParsable: false },
        { field: "Longitude", label: "Longitude", type: "number", minWidth: 90, isParsable: false },
        { field: "TimeZone", label: "Time Zone", type: 'string', minWidth: 90 },
        { field: "SalesModel", label: "Sales Model", type: 'string', minWidth: 90 },
        { field: "DistributorName", label: "Distributor Name", type: 'string', minWidth: 90 },
        { field: "PrimarySalesRep", label: "Primary Sales Rep", type: 'string', minWidth: 90, hide: true },
        {
            field: "IsActive", type: "boolean", label: "Active", minWidth: 60
        },
        { field: "SalesRepEmail", label: "Other Sales Reps", type: 'string', minWidth: 90 ,sortable:false,filterOperators: stringFilterOperators ,hide: true },
        { field: "Street2", label: "Street 2", type: 'string', minWidth: 60, hide: true },
        { field: "Street3", label: "Street 3", type: 'string', minWidth: 60,  hide: true },
        { field: "StoreOwner", label: "Store Owner", type: 'string', minWidth: 60},
        { field: "LocationSalesRep", label: "Order Email", type: 'string', minWidth: 60 },
        { field: "KeyAccount", label: "Key Account", type: 'string', minWidth: 60 },
        { field: "LocationRoute", label: "Route", type: 'string', minWidth: 60, hide: true },
        { field: "OutletGTIN", label: "Outlet GTIN", type: 'string', minWidth: 60, hide: true },
        { field: "BuyerGTIN", label: "Buyer GTIN", type: 'string', minWidth: 60, hide: true },
        { field: "DistributorGTIN", label: "Distributor GTIN", type: 'string', minWidth: 60,  hide: true },
        { field: "InfieldContactPersonEmail", label: "Infield Contact Person Email", type: 'string', minWidth: 60, hide: true },
        { field: "SecondarySalesRepEmail", label: "Secondary Sales Rep", type: 'string', minWidth: 60,  hide: true},
        { field: "ContactPersonName", label: "Contact Person Name", type: 'string', minWidth: 100},
        { field: "ContactPersonPhone", label: "Contact Person Phone", type: 'strinhg', minWidth: 100}
    ]
});

export default outletTabModel;