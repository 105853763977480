import { useEffect } from "react";
import { apis, request } from "../httpUtil";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import constants from "./constants";

const freshChatScript = constants.freshChatScript;

const useFreshChat = ({ sidebarToggleMobile, mobile, loginData }) => {
  const host = process.env.REACT_APP_HOST;
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    const fcFrame = document.getElementById("fc_frame");
    if (fcFrame && mobile) {
      const isLoginPage = constants.freshChatScreens.includes(window.location.hash?.split('/')[1]);
      if (isLoginPage) {
        window.fcWidget.show();
      } else {
        sidebarToggleMobile ? window.fcWidget.show() : window.fcWidget.hide();
      }
    }

    const onlyForProd = host && (host.includes('portal') || host.includes('dev'));
    const scriptId = 'freshChat-script';
    let existingScript = document.getElementById(scriptId);
    const isLoginPage = constants.freshChatScreens.includes(window.location.hash?.split('/')[1]);
    const isWidgetPresent = !!existingScript?.getAttribute("widgetid");

    let initializeBot = false;
    let removeWidget = false;

    // Determine if we need to initialize or remove the widget
    if (isLoginPage && !isWidgetPresent) {
      initializeBot = true;
      removeWidget = true;
    }

    if (!isLoginPage && isWidgetPresent) {
      initializeBot = true;
      removeWidget = true;
    }

    if (!existingScript) {
      initializeBot = true;
    }

    // Remove existing widget if necessary
    if (removeWidget && existingScript) {
      if (window.fcWidget) {
        window.fcWidget.destroy();
      }
      existingScript.remove();
      existingScript = false;
    }

    // Helper function to initialize the widget after the script is loaded
    const initializeWidget = () => {
      try {
        if (window?.fcWidget) {
          window.fcWidget.destroy(); // Destroy any existing widget
        }

        const { Username, FirstName, LastName, FreshChatRestoreId, ClientUserId, ClientName } = loginData?.tags || {};

        const WIDGET_CONFIG = {
          siteId: 'CoolR-Playbook-V3',
          externalId: Username,
          restoreId: FreshChatRestoreId || null,
          config: {
            cssNames: {
              widget: "custom_fc_frame"
            }
          }
        };

        window.fcSettings = {
          onInit: function () {
            if (mobile) {
              window.fcWidget.on("widget:loaded", function () {
                if (isLoginPage) {
                  window.fcWidget.show();
                } else {
                  document.getElementById("fc_frame").classList.add("custom_fc_frame");
                  window.fcWidget.hide();
                }
              });
            }

            // Set user details
            window.fcWidget.setExternalId(Username);
            window.fcWidget.user.setFirstName(FirstName);
            window.fcWidget.user.setLastName(LastName);
            window.fcWidget.user.setEmail(Username);
            window.fcWidget.user.setProperties({ last_source: ClientName });

            // Handle user creation and restore ID update
            window.fcWidget.user.get(function (resp) {
              const status = resp?.status;
              if (status !== 200) {
                window.fcWidget.user.setProperties({
                  firstName: FirstName,
                  lastName: LastName,
                  email: Username
                });
                window.fcWidget.on('user:created', function (resp) {
                  const { status, data } = resp;
                  if (status === 200 && data?.restoreId && data.externalId === Username) {
                    const url = `${apis.ClientUser}?action=updateRestoreId&clientUserId=${ClientUserId}&freshChatRestoreId=${data.restoreId}`;
                    request({ url, additionalParams: { method: "GET" }, history, dispatch })
                      .then(() => console.log('Restore ID updated successfully'))
                      .catch((err) => console.error('API error:', err));
                  }
                });
              }
            });
          }
        };

        // Apply widget configuration
        if (mobile) {
          if (!isLoginPage) {
            window.fcWidgetMessengerConfig = WIDGET_CONFIG;
          } else {
            delete WIDGET_CONFIG.config;
            window.fcWidgetMessengerConfig = WIDGET_CONFIG;
          }
        } else {
          delete WIDGET_CONFIG.config;
          window.fcWidgetMessengerConfig = WIDGET_CONFIG;
        }
      } catch (error) {
        console.error(`FreshChat Initialization Error -> ${error}`);
      }
    };

    // Function to load the FreshChat script and ensure initialization happens after load
    const loadScript = () => {
      if (!existingScript) {
        const script = document.createElement('script');
        script.src = freshChatScript + `?version=${Date.now()}`;
        script.id = scriptId;
        script.async = true;
        if (isLoginPage) {
          script.setAttribute('widgetId', '4ed457ee-c20f-407e-866a-36de4c43f6b7');
        }
        // Wait for the script to fully load before initializing
        script.onload = function () {
          initializeWidget();
        };

        script.onerror = function (error) {
          console.error('Error loading FreshChat script:', error);
        };

        document.body.appendChild(script);
      } else {
        // Script already exists, directly initialize the widget
        initializeWidget();
      }
    };

    // Only load the script and initialize if necessary
    if (initializeBot && onlyForProd) {
      loadScript();      
    }

  }, [loginData, mobile, sidebarToggleMobile, host, dispatch, history]);

};

export default useFreshChat;
