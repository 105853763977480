import UiModel from './ui-model';
import utils from '../utils';
const AssetPerformanceReportList = new UiModel({
    title: "Asset Performance Report",
    linkColumn: 'AssetSerialNumber',
    defaultSort: 'SDLastPing DESC',
    titleDescription: 'Asset Performance Report',
    readOnly: true,
    idProperty: 'AssetSerialNumber',
    module: 'AssetPerfomanceReport',
    api: 'AssetPerfomanceReport',
    addCreatedModifiedColumns: false,
    hideBackButton: true,
    preferenceId: 'playbookVistaZReporting',
    columns: [
        { field: "SerialNumber", type: 'string', label: "Serial #", required: true, width: 160},
        { field: "AssetSerialNumber", type: 'string', label: "Asset Serial #", required: true, width: 150},
        { field: "ClientName", type: 'string', label: "Client Name", required: false, width: 120 },
        { field: "DoorHandleLocationType", type: 'string', label: "Door Handle Location Type", required: false, width: 150 },
        {
            field: "Installation", type: 'date', label: "Installed At", required: false, width: 120, valueFormatter: params => {
                return utils.formatDate(params.value, false, true)
            }, keepUTC: true
        },
        { field: "SDLastPing", type: 'date', label: "SD Last Ping", required: false, width: 150, valueFormatter: params => {
            return utils.formatDate(params.value, false, true)
        }, keepUTC: true },
        { field: "DoorClose", type: 'date', label: "Door Close", required: false, width: 150,  valueFormatter: params => {
            return utils.formatDate(params.value, false, true)
        }, keepUTC: true },
        { field: "DaysSinceInstall", type: 'number', label: "Days Since Install", required: false, width: 150 },
        { field: "DaysWithImages", type: 'number', hide: true, label: "Days With Images", required: false, width: 150 },
        { field: "ImagesInLast3Days", type: 'number', hide: true, label: "Images In Last 3Days", required: false, width: 100 },
        { field: "RejectedImagesInLast3Days", type: 'number', hide: true, label: "Rejected Images In Last 3Days", required: false, width: 100 },
        { field: "ImagesInLast5Days", type: 'number', hide: true, label: "Images In Last 5Days", required: false, width: 100 },
        { field: "PercentImages", type: 'number', hide: true, label: "Percent Images", required: false, width: 100 },
        { field: "MissingImageDays", type: 'number', hide: true, label: "Missing Image Days", required: false, width: 150 },
        { field: "DaysSinceLastPing", type: 'number', hide: true, label: "Days Since Last Ping", required: false, width: 150 },
        { field: "Diagnosis", type: 'string', hide: true, label: "Diagnosis", required: false, width: 150 },
        { field: "CabinetType", type: 'string', label: 'Cabinet Type', required: false, width: 100 }
    ]
});

export default AssetPerformanceReportList; 